import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  categories,
  length,
  online,
}) => {
  const [t] = useTranslation();
  const {
    onDelete,
    alphabetMask,
    emailAddressMask,
    birthDateMask,
    alphanumMask,
  } = useMask();
  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.title.information')}{' '}
        {length === 1 ? '' : `${index + 1}º`}{' '}
        {t('payment.attendee.title.attendee')}
      </h3>
      <Row className='mt-2'>
        <Col md={online ? 12 : 6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-name`}
          >
            {t('payment.attendee.fullname')}*
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        {online === false && (
          <Col md={6}>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-namebadge`}
            >
              {t('payment.attendee.namebadge')}*
            </label>
            <input
              id={`attendee-${index}-namebadge`}
              className='form-control'
              autoComplete='off'
              value={attendee.namebadge}
              maxLength={20}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = alphabetMask(value);
                newValue = newValue.substring(0, 20).toUpperCase();
                fillAttendee('namebadge', newValue, index);
              }}
            />
          </Col>
        )}
      </Row>
      <Row className='mt-2'>
        <Col md={3}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-birthdate`}
          >
            {t('payment.attendee.birthdate')}
          </label>
          <input
            id={`attendee-${index}-birthdate`}
            maxLength={10}
            className='form-control'
            placeholder='dd/mm/aaaa'
            autoComplete='off'
            value={attendee.birthdate}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = birthDateMask(value);
              fillAttendee('birthdate', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.birthdate);
              fillAttendee('birthdate', newValue, index);
            }}
          />
        </Col>

        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-email`}
          >
            {t('payment.attendee.email')}*
          </label>
          <div className='d-flex flex-row justify-content-start align-items-center gap-2'>
            <input
              type='email'
              id={`attendee-${index}-email`}
              className='form-control text-start'
              name='email'
              autoComplete='off'
              value={attendee.email}
              onChange={(e) => {
                const { value } = e.target;
                fillAttendee('email', value, index);
              }}
            />
          </div>
        </Col>
        <Col md={3}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-franchisee`}
          >
            {t('payment.attendee.franchisee')}
          </label>
          <input
            id={`attendee-${index}-franchisee`}
            className='form-control'
            autoComplete='off'
            value={attendee.franchisee}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillAttendee('franchisee', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

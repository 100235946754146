import './styled.css';
import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PixImg from '../../assets/images/pix.png';
import CartoesImg from '../../assets/images/cartoes.png';
import Loading from '../../assets/images/loading.gif';

import Header from '../../components/header';
import { Location } from '../../components/location';
import { Attendee } from '../../components/attendee';
import { Token } from '../../components/token';
import { Customer } from '../../components/customer';
import { Pix } from '../../components/pix';
import { Modal } from '../../components/modal';
import { WarningAdBlock } from '../../components/warningadblock';

import { TransactionContext } from '../../contexts/TransactionContext';

import { useError } from '../../hooks/useError';
import { useModelDefault } from '../../hooks/useModelDefault';
import { useList } from '../../hooks/useList';
import { useMask } from '../../hooks/useMask';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import { useVar } from '../../hooks/useVar';

import { transactions } from '../../utils/service/payment';
import { calcPromo } from '../../utils/service/product';

import { useTranslation } from 'react-i18next';

export default function Payment() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const {
    selectedProduct,
    setTransactionResponse,
    setPaymentType,
    transactionResponse,
    paymentType,
    setRemainingTime,
    login,
  } = useContext(TransactionContext);

  const {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    billingDefault,
    transactionDefault,
  } = useModelDefault();

  const {
    attendeeError,
    customerError,
    customerFreeError,
    cctokenError,
    goToFieldWithError,
  } = useError();

  const { PIX, CREDITCARD, FREE } = usePaymentOption();

  const { categories } = useList();

  const { brlMask } = useMask();

  const { testMode, accountId } = useVar();

  const [attendees, setAttendees] = useState(
    Array.from(
      { length: parseInt(selectedProduct.tag) },
      () => attendeeDefault,
    ),
  );

  const [transaction, setTransaction] = useState(transactionDefault);
  const [customer, setCustomer] = useState(customerDefault);
  const [billing, setBilling] = useState(billingDefault);
  const [cctoken, setCctoken] = useState(cctokenDefault);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [htmlId, setHtmlId] = useState('');
  const [currentPromo, setCurrentPromo] = useState('');
  const [currentPrice, setCurrentPrice] = useState(selectedProduct.price);
  const [promocodeLoading, setPromoCodeLoading] = useState(false);
  const [promocodeError, setPromoCodeError] = useState(false);
  const [free, setFree] = useState(false);
  const [currentPriceColor, setCurrentPriceColor] = useState('#495057');
  const [showQrCode, setShowQrCode] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  const [enabledButton, setEnableButton] = useState(false);

  const enablePromocode = false;

  const docs = t('documents', { returnObjects: true });
  const identification = docs
    ? docs?.find((x) => x.country === login.country)?.name
    : '';

  const validateAttendee = () => {
    let errorFirstFor = false;
    let errorSecondFor = false;
    for (let index = 0; index < attendees.length; index++) {
      const attendee = attendees[index];
      console.log('attendee', attendee);
      console.log(attendeeError);
      for (let property in attendeeError) {
        let field = attendeeError[property];
        for (let current in field) {
          if (
            field[current.toString()].func(
              attendee,
              selectedProduct.online,
              login.language,
            )
          ) {
            const id = `attendee-${index}-${property}`;
            const msg = `fields.attendee.${property}`;
            setHtmlId(id);
            setMessage(t(msg));
            openModal();
            errorSecondFor = true;
            errorFirstFor = true;
            break;
          }
        }
        if (errorSecondFor) {
          break;
        }
      }
      if (errorFirstFor) {
        break;
      }
    }
    return !errorFirstFor;
  };

  const validate = (model, error, name) => {
    let errorFor = false;
    for (let property in error) {
      const field = error[property];
      for (let current in field) {
        if (field[current.toString()].func(model)) {
          const id = `${name}-${property}`;
          setHtmlId(id);
          const msg = `fields.${name}.${property}`;
          setMessage(t(msg));
          openModal();
          errorFor = true;
          break;
        }
      }
      if (errorFor) {
        break;
      }
    }
    return !errorFor;
  };

  const [adblock, setAdBlock] = useState(false);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    goToFieldWithError(htmlId);
  };

  const closeModalPromoCode = () => {
    setPromoCodeError(false);
    goToFieldWithError('current-promo');
  };
  const openModal = () => setOpen(true);

  const createPaymentToken = async () => {
    if (transaction.paymentType !== CREDITCARD) return '';

    const name = cctoken.name.split(' ');

    const firstName = name.shift();
    const lastName = name.pop();

    const expireAt = cctoken.expireAt.split('/');
    const month = expireAt.shift();
    const year = expireAt.pop();

    window.Iugu.setAccountID(accountId);
    window.Iugu.setTestMode(testMode);
    const creditCard = window.Iugu.CreditCard(
      cctoken.number,
      month,
      year,
      firstName,
      lastName,
      cctoken.code,
    );

    return new Promise(async (resolve) => {
      try {
        window.Iugu.createPaymentToken(creditCard, (response) => {
          if (response.errors) {
            Sentry.setContext('createPaymentTokenResponse', { response });
            Sentry.captureMessage('createPaymentToken');
            console.log('createPaymentToken', response.errors);
            resolve('');
          } else {
            resolve(response.id);
          }
        });
      } catch (error) {
        Sentry.setContext('error', { error });
        Sentry.captureMessage('createPaymentToken');
        console.log('createPaymentToken', error);
        resolve('');
      }
    });
  };

  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const validations = {
    attendees: () => validateAttendee(),
    creditCard: () => {
      if (transaction.paymentType === CREDITCARD) {
        return validate(cctoken, cctokenError, 'cctoken');
      }
      return true;
    },
    customer: () => {
      if (transaction.paymentType === FREE) {
        return validate(customer, customerFreeError, 'customer');
      }
      return validate(customer, customerError, 'customer');
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEnableButton(true);
    for (let validation in validations) {
      if (!validations[validation]()) {
        setEnableButton(false);
        return;
      }
    }

    transaction.token = await createPaymentToken();
    transaction.customer = customer;
    transaction.attendees = attendees;
    transaction.login = login;
    transaction.installments =
      transaction.paymentType === CREDITCARD
        ? parseInt(cctoken.installments)
        : 1;

    setLoading(true);
    const response = await transactions(transaction);

    setPaymentType(transaction.paymentType);
    setTransactionResponse(response);

    const generated = response.success && transaction.paymentType === PIX;
    setRemainingTime(300);
    setShowQrCode(generated);
    setShowSubmitButton(!generated);
    setLoading(false);

    if (transaction.paymentType !== PIX || response.success === false) {
      navigate('/finalizacao');
    }

    setTimeout(() => {
      setEnableButton(false);
    }, 2000);
  };

  const handlePromoCode = async (e) => {
    e.preventDefault();

    if (currentPromo === '' || promocodeError) {
      return;
    }

    setPromoCodeLoading(true);

    const promoCalc = await calcPromo(currentPromo, selectedProduct.id);

    if (promoCalc) {
      fillTransaction('promoCode', currentPromo);
      const newPrice = Number(promoCalc.price);
      if (newPrice === 0) {
        setFree(true);
        fillTransaction('paymentType', FREE);
      } else {
        setFree(false);
        fillTransaction('paymentType', PIX);
      }

      setCurrentPrice(newPrice);
      setPromoCodeLoading(false);
      setCurrentPriceColor('#26a47b');
      setTimeout(() => {
        setCurrentPriceColor('#495057');
      }, 3000);
    } else {
      setPromoCodeLoading(false);
      setPromoCodeError(true);
    }
  };

  useEffect(() => {
    if (selectedProduct.id === '') {
      navigate('/');
    }

    const isBlockedByAdBlock = window.Iugu.utils.isBlockedByAdBlock();
    setAdBlock(isBlockedByAdBlock);

    setCurrentPrice(selectedProduct.price);
    fillTransaction('paymentType', login.language === 'pt' ? PIX : CREDITCARD);
    fillTransaction('products', [selectedProduct.id]);
  }, [
    navigate,
    selectedProduct.id,
    selectedProduct.price,
    login.language,
    PIX,
    CREDITCARD,
  ]);

  return (
    <section id='section-payment'>
      <Modal
        open={adblock}
        closeModal={(e) => {
          setAdBlock(false);
          navigate('/');
        }}
      >
        <WarningAdBlock />
      </Modal>
      <Modal
        open={promocodeError}
        closeModal={closeModalPromoCode}
      >
        {t('payment.promocode.title')} {currentPromo}{' '}
        {t('payment.promocode.error')}
      </Modal>

      <Modal
        open={open}
        closeModal={closeModal}
      >
        {message}
      </Modal>

      <Header />
      <Row>
        <Card className='card-payment'>
          <CardBody>
            <Row>
              <Col md={6}>
                <Location />
              </Col>
              <Col md={6}>
                <div className='total-amount'>
                  <div>{selectedProduct.description}</div>
                  <div>{brlMask(selectedProduct.price)}</div>
                </div>

                <div className='total-amount'>
                  <div className='title2'>{t('payment.amount')}</div>
                  <div style={{ color: currentPriceColor }}>
                    {brlMask(currentPrice)}
                  </div>
                </div>

                {enablePromocode && (
                  <>
                    <div className='total-amount'>
                      <div className='title2'>
                        {t('payment.promocode.title')}
                      </div>
                    </div>
                    <form autoComplete='off'>
                      <div className='total-amount'>
                        <div className='promo-wrapper'>
                          <input
                            type='text'
                            id='current-promo'
                            className='form-control'
                            value={currentPromo}
                            autoComplete='off'
                            onChange={(e) =>
                              setCurrentPromo(
                                e.target.value.trim().toUpperCase(),
                              )
                            }
                          />
                          <button
                            id='btn-promo-code'
                            className='btn-submit'
                            onClick={handlePromoCode}
                          >
                            {promocodeLoading ? (
                              <img
                                style={{
                                  width: '16px',
                                }}
                                src={Loading}
                                alt='Aplicando cupom, aguarde'
                              />
                            ) : (
                              <>{t('payment.promocode.apply')}</>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Col>
            </Row>

            <form autoComplete='off'>
              {attendees.map((attendee, index) => (
                <Attendee
                  key={index}
                  index={index}
                  attendee={attendee}
                  length={attendees.length}
                  categories={categories}
                  fillAttendee={fillAttendee}
                  online={selectedProduct.online}
                />
              ))}

              <Row className='mt-4 '>
                <Col md={12}>
                  <div className='form-check'>
                    <input
                      id={`customer-doyouagree`}
                      className='form-check-input'
                      type='checkbox'
                      checked={customer.doyouagree}
                      onChange={(e) =>
                        fillCustomer('doyouagree', !customer.doyouagree)
                      }
                    ></input>
                    <label className='form-check-label'>
                      {t('payment.customer.read1')}{' '}
                      <a
                        href={t('payment.customer.url1')}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        {t('payment.customer.registrationterms')}
                      </a>{' '}
                      {t('payment.customer.read2')}
                      <a
                        href={t('payment.customer.url2')}
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        {' '}
                        {t('payment.customer.privacypolicy')}
                        {'.'}
                      </a>
                    </label>
                  </div>
                </Col>
              </Row>

              {login.language === 'pt' && (
                <>
                  <h3
                    className='title2'
                    style={{ marginTop: 20 }}
                  >
                    {t('payment.option.title')}
                  </h3>
                  <Row style={{ margin: '20px 0' }}>
                    <div className='div-option-payment'>
                      <h6>{t('payment.option.pix')}</h6>
                      <img
                        alt='Forma de pagamento, pix'
                        src={PixImg}
                        style={{ width: 40, margin: '10px 0' }}
                      />
                      <input
                        id='transaction-payment-type-pix'
                        type='radio'
                        className='form-check-input'
                        value={transaction.paymentType}
                        checked={transaction.paymentType === PIX}
                        onChange={(e) => {
                          if (
                            transactionResponse.success &&
                            paymentType === PIX
                          ) {
                            setShowQrCode(true);
                            setShowSubmitButton(false);
                          }
                          fillTransaction('paymentType', PIX);
                        }}
                      />
                    </div>
                    <div
                      className='div-option-payment'
                      style={{ borderRight: 'none' }}
                    >
                      <h6>{t('payment.option.creditcard')}</h6>
                      <img
                        alt='Forma de pagamento, cartão de crédito'
                        src={CartoesImg}
                        style={{ width: 95, margin: '10px 0' }}
                      />
                      <input
                        id='transaction-payment-type-credit-card'
                        type='radio'
                        className='form-check-input'
                        value={transaction.paymentType}
                        checked={transaction.paymentType === CREDITCARD}
                        onChange={(e) => {
                          setShowQrCode(false);
                          setShowSubmitButton(true);
                          fillTransaction('paymentType', CREDITCARD);
                        }}
                      />
                    </div>
                  </Row>
                </>
              )}

              {transaction.paymentType === CREDITCARD && (
                <Token
                  cctoken={cctoken}
                  fillToken={fillToken}
                  amount={currentPrice}
                  maxInstallments={selectedProduct.installments}
                />
              )}

              <Customer
                customer={customer}
                fillCustomer={fillCustomer}
                free={free}
                paymentType={transaction.paymentType}
                identification={identification}
              />

              {showSubmitButton && (
                <Row className='row-btn-submit'>
                  <button
                    id='btn-buy'
                    className='btn-submit'
                    onClick={handleSubmit}
                    disabled={enabledButton}
                  >
                    {loading ? (
                      <img
                        style={{
                          width: '24px',
                        }}
                        src={Loading}
                        alt='Efetuando a transação, aguarde'
                      />
                    ) : (
                      <>{t('cart.buy')}</>
                    )}
                  </button>
                </Row>
              )}
            </form>
            {showQrCode && (
              <Pix
                qrCode={transactionResponse.data?.pix?.qrcode}
                qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
                transactionId={transactionResponse?.data?.transactionId}
              />
            )}
          </CardBody>
        </Card>
      </Row>
    </section>
  );
}
